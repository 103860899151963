import DashboardLayout from '../components/Zyro/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const ZyroOverview = () => import( /* webpackChunkName: "widgets" */ 'src/components/Zyro/Views/Dashboard/ZyroOverview.vue')
const Widgets = () => import( /* webpackChunkName: "widgets" */ 'src/components/Zyro/Views/Dashboard/Widgets.vue')

const Wizard = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Forms/Wizard.vue')

//Locations
const LocationForm = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Locations/Create.vue')
const LocationViewForm = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Locations/View.vue')
const LocationEditForm = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Locations/Edit.vue')
const LocationsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Locations/List.vue')

// Employees
const EmployeeWizard = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Employees/Wizard.vue')
const EmployeesTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Employees/List.vue')
const EmployeeViewForm = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Employees/View.vue')
const EmployeeEditForm = () => import('src/components/Zyro/Views/Employees/Edit.vue')
// Doctors
const DoctorWizard = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Doctors/Wizard.vue')
const DoctorsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Doctors/List.vue')
const DoctorViewForm = () => import('src/components/Zyro/Views/Doctors/View.vue')
const DoctorEditForm = () => import('src/components/Zyro/Views/Doctors/Edit.vue')

// Patients
const PatientWizard = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Patients/Wizard.vue')
const PatientsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Patients/List.vue')
const PatientViewForm = () => import('src/components/Zyro/Views/Patients/View.vue')
const PatientEditForm = () => import('src/components/Zyro/Views/Patients/Edit.vue')

// Leads
const LeadWizard = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Leads/Wizard.vue')
const LeadsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Leads/List.vue')
const LeadViewForm = () => import('src/components/Zyro/Views/Leads/View.vue')
const LeadEditForm = () => import('src/components/Zyro/Views/Leads/Edit.vue')

// Inventory
const InventoryTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Inventory/List.vue')

// Products
const ProductForm = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Products/Create.vue')
const ProductViewForm = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Products/View.vue')
const ProductEditForm = () => import( /* webpackChunkName: "forms" */ 'src/components/Zyro/Views/Products/Edit.vue')
const ProductsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Products/List.vue')


// Prescriptions
const PrescriptionForm = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Prescriptions/Create.vue')
const PrescriptionFormEdit = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Prescriptions/Edit.vue')
const PrescriptionsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Prescriptions/List.vue')

// Billings
const BillingForm = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Billings/Create.vue')
const BillingFormNew = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Billings/CreateNew.vue')
const BillingFormEdit = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Billings/Edit.vue')
const BillingsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Billings/List.vue')


// Attributes
//   Settings
const SettingsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Settings/Manage.vue')
//   Settings
const SettingsImagesTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/SettingsImages/Manage.vue')
//   Dosages
const DosagesTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Dosages/Manage.vue')
//   Frequencies
const FrequenciesTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Frequencies/Manage.vue')
//   Comments
const CommentsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Comments/Manage.vue')
//   Product Types
const ProductTypesTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/ProductTypes/Manage.vue')
//   Discounts
const DiscountsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Discounts/Manage.vue')
//   Roles
const RolesTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Roles/Manage.vue')
// RecordTypes
const RecordTypesTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/RecordTypes/Manage.vue')
// GSTTypes
const GSTTypesTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/GSTTypes/Manage.vue')
// Payment Modes
const ModesTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Modes/Manage.vue')
// Diseases Modes
const DiseasesTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Attributes/Diseases/Manage.vue')

// Appointmemnts
const AppointmentsTable = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Appointments/Manage.vue')

// Events
const EventsTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Events/Manage.vue')

// Records
const RecordsTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Records/Manage.vue')

// Infos
const InfosTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Infos/Manage.vue')

// Reports
const ReportsList = () => import( /* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Reports/List.vue')

// Payments
const PaymentsTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Payments/Manage.vue')

// Consultations
const ConsultationsTable = () => import(/* webpackChunkName: "tables" */ 'src/components/Zyro/Views/Consultations/Manage.vue')
// User Profile View
const ProfileView = () => import('src/components/Zyro/Views/Pages/UserProfile.vue')


import Empty from 'src/components/Zyro/Views/Dashboard/Empty.vue'
// Pages
import User from 'src/components/Zyro/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Zyro/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Zyro/Views/Pages/Login.vue'
import Register from 'src/components/Zyro/Views/Pages/Register.vue'
import Lock from 'src/components/Zyro/Views/Pages/Lock.vue'

// Calendar
import Calendar from 'src/components/Zyro/Views/Calendar/CalendarRoute.vue'

// Charts
const Charts = () => import( /* webpackChunkName: "widgets" */ 'src/components/Zyro/Views/Charts.vue')


const routes = [
    {
        path: '/',
        component: DashboardLayout,
        redirect: '/admin/overview',
        children: [
            {
                path: 'calendar',
                name: 'Calendar',
                component: Calendar
            },
            {
                path: 'charts',
                name: 'Charts',
                component: Charts
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    { 
        path: '/logout',
        name: 'Logout', 
        component: Login
    },
    {
        path: '/lock',
        name: 'Lock',
        component: Lock
    },
    {
        path: '/admin',
        component: DashboardLayout,
        redirect: '/admin/overview',
        children: [
            {
                path: 'empty',
                name: 'Empty',
                component: Empty
            },
            {
                path: 'overview',
                name: 'Overview',
                component: ZyroOverview
            },
            {
                path: 'widgets',
                name: 'Widgets',
                component: Widgets
            },
            {
                path: 'profile/:user_id',
                name: 'Profile',
                component: ProfileView
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/locations',
        name: 'Locations',
        component: DashboardLayout,
        redirect: '/locations/list',
        children: [
            {
                path: 'list',
                name: 'Locations List',
                component: LocationsTable
            },
            {
                path: 'create',
                name: 'Location Create',
                component: LocationForm
            },
            {
                path: 'edit/:id',
                name: 'Location Edit',
                component: LocationEditForm
            },
            {
                path: 'view/:id',
                name: 'Location View',
                component: LocationViewForm
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/employees',
        name: 'Employees',
        component: DashboardLayout,
        redirect: '/employees/list',
        children: [{
            path: 'list',
            name: 'Employees List',
            component: EmployeesTable
          },
          {
            path: 'create',
            name: 'Employee Create',
            component: EmployeeWizard
          },
          {
            path: 'view/:id',
            name: 'Employee View',
            component: EmployeeViewForm
          },
          {
            path: 'edit/:id',
            name: 'Employee Edit',
            component: EmployeeEditForm
          }
        ],
        meta: {
          auth: true
        }
    },
    {
    path: '/patients',
    name: 'Patients',
    component: DashboardLayout,
    redirect: '/patients/list',
    children: [{
        path: 'list',
        name: 'Patients List',
        component: PatientsTable
        },
        {
        path: 'create',
        name: 'Patient Create',
        component: PatientWizard
        },
        {
        path: 'records',
        name: 'Patient Records',
        component: RecordsTable
        },
        {
        path: 'infos',
        name: 'Patient Info',
        component: InfosTable
        },
        {
        path: 'view/:id',
        name: 'Patient View',
        component: PatientViewForm
        },
        {
        path: 'edit/:id',
        name: 'Patient Edit',
        component: PatientEditForm
        }
    ],
    meta: {
        auth: true
    }
    },
    {
    path: '/doctors',
    name: 'Doctors',
    component: DashboardLayout,
    redirect: '/doctors/list',
    children: [{
        path: 'list',
        name: 'Doctors List',
        component: DoctorsTable
        },
        {
        path: 'create',
        name: 'Doctor Create',
        component: DoctorWizard
        },
        {
        path: 'view/:id',
        name: 'Doctor View',
        component: DoctorViewForm
        },
        {
        path: 'edit/:id',
        name: 'Doctor Edit',
        component: DoctorEditForm
        }
    ],
    meta: {
        auth: true
    }
    },
    {
    path: '/leads',
    name: 'Leads',
    component: DashboardLayout,
    redirect: '/leads/list',
    children: [{
        path: 'list',
        name: 'Leads List',
        component: LeadsTable
        },
        {
        path: 'create',
        name: 'Lead Create',
        component: LeadWizard
        }, {
        path: 'view/:id',
        name: 'Lead View',
        component: LeadViewForm
        },
        {
        path: 'edit/:id',
        name: 'Lead Edit',
        component: LeadEditForm
        }
    ],
    meta: {
        auth: true
    }
    },
    {
        path: '/products',
        name: 'Products',
        component: DashboardLayout,
        redirect: '/products/list',
        children: [
            {
                path: 'list',
                name: 'Products List',
                component: ProductsTable
            },
            {
                path: 'create',
                name: 'Product Create',
                component: ProductForm
            },
            {
                path: 'edit/:id',
                name: 'Product Edit',
                component: ProductEditForm
            },
            {
                path: 'view/:id',
                name: 'Product View',
                component: ProductViewForm
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/inventory',
        name: 'Inventory',
        component: DashboardLayout,
        redirect: '/inventory/manage',
        children: [
            {
                path: 'manage',
                name: 'Inventory List',
                component: InventoryTable
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/reports',
        name: 'Reports',
        component: DashboardLayout,
        redirect: '/reports/manage',
        children: [
            {
                path: 'manage',
                name: 'Reports List',
                component: ReportsList
            }
        ],
        meta: {
            auth: true
        }
    },
    // {
    //     path: '/roles',
    //     name: 'Roles',
    //     component: DashboardLayout,
    //     redirect: '/roles/list',
    //     children: [
    //         {
    //             path: 'list',
    //             name: 'Roles List',
    //             component: RolesTable
    //         },
    //         {
    //             path: 'create',
    //             name: 'Roles Create',
    //             component: RoleForm
    //         }
    //     ],
    //     meta: {
    //         auth: true
    //     }
    // },
    {
        path: '/manage',
        name: 'Manage',
        component: DashboardLayout,
        redirect: '/manage/settings',
        children: [
            {
                path: 'settings',
                name: 'Settings Manage',
                component: SettingsTable
            },
            {
                path: 'settings-images',
                name: 'Settings Images Manage',
                component: SettingsImagesTable
            },
            {
                path: 'dosages',
                name: 'Dosages Manage',
                component: DosagesTable
            },
            {
                path: 'frequencies',
                name: 'Frequencies Manage',
                component: FrequenciesTable
            },
            {
                path: 'comments',
                name: 'Comments Manage',
                component: CommentsTable
            },
            {
                path: 'product-types',
                name: 'Product Types Manage',
                component: ProductTypesTable
            },
            {
                path: 'discounts',
                name: 'Discounts Manage',
                component: DiscountsTable
            },
            {
                path: 'roles',
                name: 'Roles Manage',
                component: RolesTable
            },
            {
                path: 'record-types',
                name: 'Record Types Manage',
                component: RecordTypesTable
            },
            {
                path: 'gst-types',
                name: 'GST Types Manage',
                component: GSTTypesTable
            },
            {
                path: 'modes',
                name: 'Payment Modes Manage',
                component: ModesTable
            },
            {
                path: 'diseases',
                name: 'Diseases Manage',
                component: DiseasesTable
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/prescriptions',
        name: 'Prescription',
        component: DashboardLayout,
        redirect: '/prescriptions/create',
        children: [
            {
                path: 'list',
                name: 'Prescriptions List',
                component: PrescriptionsTable
            },
            {
                path: 'create',
                name: 'Prescription Create',
                component: PrescriptionForm
            },
            {
                path: 'edit/:prescription_id',
                name: 'Prescription Edit',
                component: PrescriptionFormEdit
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/consultations',
        name: 'Consultations',
        component: DashboardLayout,
        redirect: '/consultations/manage',
        children: [
            {
                path: 'manage',
                name: 'Consultations Manage',
                component: ConsultationsTable
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/billings',
        name: 'Bill',
        component: DashboardLayout,
        redirect: '/billings/create',
        children: [
            {
                path: 'list',
                name: 'Bill List',
                component: BillingsTable
            },
            {
                path: 'create/:prescription_id',
                name: 'Bill Create from Prescription',
                component: BillingForm
            },
            {
                path: 'edit/:bill_id',
                name: 'Bill Edit',
                component: BillingFormEdit
            },
            {
                path: 'create',
                name: 'Bill Create',
                component: BillingFormNew
            },
            {
                path: 'payments/:bill_id',
                name: 'Payments Manage',
                component: PaymentsTable
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/appointments',
        name: 'Appointment',
        component: DashboardLayout,
        redirect: '/appointments/manage',
        children: [
            {
                path: 'manage',
                name: 'Appointments Manage',
                component: AppointmentsTable
            },
            {
                path: 'manage/:appointment_id',
                name: 'Appointment Manage',
                component: AppointmentsTable
            }
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/events',
        name: 'Event',
        component: DashboardLayout,
        redirect: '/events/manage',
        children: [
            {
                path: 'manage',
                name: 'Events Manage',
                component: EventsTable
            },
            {
                path: 'manage/:event_id',
                name: 'Event Manage',
                component: EventsTable
            }
        ],
        meta: {
            auth: true
        }
    },
    {path: '*', component: NotFound}

];

export default routes
