export default [
  {
    name: 'Dashboard',
    icon: 'fa fa-tachometer-alt',
    path: '/admin/overview'
  },
  {
    name: 'Calendar',
    icon: 'fa fa-calendar-alt',
    path: '/calendar'
  },
  // {
  //   name: 'Pages',
  //   icon: 'far fa-copy',
  //   children: [
  //     // {
  //     //   name: 'User Profile',
  //     //   path: '/pages/user'
  //     // },
  //     {
  //       name: 'Login',
  //       path: '/login'
  //     },
  //     {
  //       name: 'Lock',
  //       path: '/lock'
  //     }
  //   ]
  // },
  {
    name: 'Locations',
    icon: 'far fa-building',
    children: [
      {
        name: 'View All',
        path: '/locations/list'
      },
      {
        name: 'Create New',
        path: '/locations/create'
      }
    ]
  },
  {
    name: 'Employees & Doctors',
    icon: 'fa fa-user-friends',
    children: [
      {
        name: 'Create New',
        path: '/employees/create'
      },
      {
        name: 'View All Employees',
        path: '/employees/list'
      },
      {
        name: 'View All Doctors',
        path: '/doctors/list'
      },
      
      // {
      //   name: 'Create New Doctor',
      //   path: '/doctors/create'
      // }
    ]
  },
  // {
  //   name: 'Doctors',
  //   icon: 'fa fa-user-md',
  //   children: [
  //     {
  //       name: 'View',
  //       path: '/doctors/list'
  //     },
  //     {
  //       name: 'Create New',
  //       path: '/doctors/create'
  //     }
  //   ]
  // },
  {
    name: 'Patients & Leads',
    icon: 'fa fa-id-card',
    children: [
      {
        name: 'Create New',
        path: '/patients/create'
      },
      {
        name: 'View All Patients',
        path: '/patients/list'
      },
      {
        name: 'View All Leads',
        path: '/leads/list'
      },
      {
        name: 'Records',
        path: '/patients/records'
      },
      {
        name: 'Infos',
        path: '/patients/infos'
      },
      {
        name: '',
        path: '#',
        separator: true
      },
      {
        name: 'Consultations',
        path: '/consultations/manage'
      },
      {
        name: 'Prescription List',
        path: '/prescriptions/list'
      },
      {
        name: 'Prescription Create',
        path: '/prescriptions/create'
      },
      {
        name: 'Bills Create',
        path: '/billings/create'
      },
      {
        name: 'Bills List',
        path: '/billings/list'
      }
      
    ]
  },
  // {
  //   name: 'Leads',
  //   icon: 'far fa-id-card',
  //   children: [
  //     {
  //       name: 'View',
  //       path: '/leads/list'
  //     },
  //     {
  //       name: 'Create New',
  //       path: '/leads/create'
  //     }
  //   ]
  // },
  {
    name: 'Inventory',
    icon: 'fa fa-store-alt',
    children: [
      {
        name: 'Create Product',
        path: '/products/create'
      },
      {
        name: 'View Products',
        path: '/products/list'
      },
      {
        name: 'Manage Stock',
        path: '/inventory/manage'
      }
    ]
  },
  // {
  //   name: 'Prescription & Billing',
  //   icon: 'fa fa-newspaper',
  //   children: [
  //     {
  //       name: 'Prescription List',
  //       path: '/prescriptions/list'
  //     },
  //     {
  //       name: 'Prescription Create',
  //       path: '/prescriptions/create'
  //     },
  //     {
  //       name: 'Bills Create',
  //       path: '/billings/create'
  //     },
  //     {
  //       name: 'Bills List',
  //       path: '/billings/list'
  //     }
  //   ]
  // },
  // {
  //   name: 'Billing',
  //   icon: 'fa fa-credit-card',
  //   children: [
  //     {
  //       name: 'Bills Create',
  //       path: '/billings/create'
  //     },
  //     {
  //       name: 'Bills List',
  //       path: '/billings/list'
  //     }
  //   ]
  // },
  {
    name: 'Appointments & Events',
    icon: 'fa fa-calendar',
    children: [
      {
        name: 'Appointments Manage',
        path: '/appointments/manage'
      },
      {
        name: 'Events Manage',
        path: '/events/manage'
      }
    ]
  },
  // {
  //   name: 'Events',
  //   icon: 'fa fa-calendar-alt',
  //   children: [
  //     {
  //       name: 'Events Manage',
  //       path: '/events/manage'
  //     }
  //   ]
  // },
  {
    name: 'Reports',
    icon: 'fa fa-table',
    children: [
      {
        name: 'Reports Manage',
        path: '/reports/manage'
      }
    ]
  },
  {
    name: 'Manage',
    icon: 'fa fa-cogs',
    children: [
      {
        name: 'Settings Manage',
        path: '/manage/settings'
      },
      {
        name: 'Settings Image Manage',
        path: '/manage/settings-images'
      },
      {
        name: 'Dosages Manage',
        path: '/manage/dosages'
      },
      {
        name: 'Frequencies Manage',
        path: '/manage/frequencies'
      },
      {
        name: 'Comments Manage',
        path: '/manage/comments'
      },
      {
        name: 'Product Types Manage',
        path: '/manage/product-types'
      },
      {
        name: 'Discounts Manage',
        path: '/manage/discounts'
      },
      {
        name: 'Roles Manage',
        path: '/manage/roles'
      },
      {
        name: 'Record Types Manage',
        path: '/manage/record-types'
      },
      {
        name: 'GST Types Manage',
        path: '/manage/gst-types'
      },
      {
        name: 'Payment Modes Manage',
        path: '/manage/modes'
      },
      {
        name: 'Diseases Manage',
        path: '/manage/diseases'
      }
    ]
  },
]
