
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

import getters from './getters'
import mutations from './mutations'
import actions from './actions'




export const store = new Vuex.Store({
    state: {
        stats: null, 
        currentUser: null,
        isLoggedIn: false,
        isLoading: false,
        patients: [],
        patient: null,
        leads: [],
        lead: null,
        locations: [],
        location: null,
        employees: [],
        employee: null,
        doctors: [],
        doctor: null,
        inventories: [],
        inventory: null,
        products: [],
        product: null,
        roles: [],
        pagination: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: "",
            per_page: "10",
            to: 1,
            total: 1
        },
        prescription: null,
        prescriptions: [],
        prescription_items: [
            {
                prescription_id: null,
                product_id: null,
                product: null,
                frequency: null,
                dose_1: null,
                dose_2: null,
                dose_3: null,
                comment: null,
                extra: null,
                unit: 'no.s'
            }
        ],
        product_items: [
            {
                product: {
                    id: null,
                    sale_price: 0,
                    quantity: 0,
                    product_type:{
                        name: '-'
                    },
                    gst_type: {
                        percentage: 0
                    }
                },
                quantity: 1,
                discount: 33,
                amount: 0

            }
        ],
        billing: null,
        billings: [],
        genders: [],
        marital_statuses: [],
        religions: [],
        blood_groups: [],
        consultation_fee: 0,
        appointments: [],
        appointment: null,
        appointment_types: [],
        transfers: [],
        transfer: null,
        settings: [],
        setting: null,
        images: [],
        image: null,
        dosages: [],
        dosage: null,
        frequencies: [],
        frequency: null,
        comments: [],
        comment: null,
        product_types: [],
        product_type: null,
        discounts: [],
        discount: null,
        roles: [],
        role: null,
        record_types: [],
        record_type: null,
        gst_types: [],
        gst_type: null,
        records: [],
        record: null,
        modes: [],
        mode: null,
        events: [],
        event: null,
        infos: [],
        info: null,
        diseases: [],
        disease: null,
        payments: [],
        payment: null,
        consultations: [],
        consultation: null,
        patient_consultation: {
            is_expired: true,
            days: 0
        },
        countries: [],
        states: [
            {
                "id": 65,
                "country": "IND",
                "name": "Jammu and Kashmir",
                "abbr": "JK",
                "area": "12541302SKM",
                "largest_city": "Srinagar Jammu",
                "capital": "Srinagar Jammu"
            },
            {
                "id": 66,
                "country": "IND",
                "name": "Jharkhand",
                "abbr": "JH",
                "area": "32988134SKM",
                "largest_city": "Ranchi",
                "capital": "Ranchi"
            },
            {
                "id": 67,
                "country": "IND",
                "name": "Karnataka",
                "abbr": "KA",
                "area": "61095297SKM",
                "largest_city": "Bangalore",
                "capital": "Bangalore"
            },
            {
                "id": 68,
                "country": "IND",
                "name": "Kerala",
                "abbr": "KL",
                "area": "33406061SKM",
                "largest_city": "Thiruvananthapuram",
                "capital": "Thiruvananthapuram"
            },
            {
                "id": 69,
                "country": "IND",
                "name": "Madhya Pradesh",
                "abbr": "MP",
                "area": "72626809SKM",
                "largest_city": "Bhopal",
                "capital": "Bhopal"
            },
            {
                "id": 70,
                "country": "IND",
                "name": "Maharashtra",
                "abbr": "MH",
                "area": "112374333SKM",
                "largest_city": "Mumbai",
                "capital": "Mumbai"
            },
            {
                "id": 71,
                "country": "IND",
                "name": "Manipur",
                "abbr": "MN",
                "area": "2855794SKM",
                "largest_city": "Imphal",
                "capital": "Imphal"
            },
            {
                "id": 72,
                "country": "IND",
                "name": "Meghalaya",
                "abbr": "ML",
                "area": "2966889SKM",
                "largest_city": "Shillong",
                "capital": "Shillong"
            },
            {
                "id": 73,
                "country": "IND",
                "name": "Mizoram",
                "abbr": "MZ",
                "area": "1097206SKM",
                "largest_city": "Aizawl",
                "capital": "Aizawl"
            },
            {
                "id": 74,
                "country": "IND",
                "name": "Nagaland",
                "abbr": "NL",
                "area": "1978502SKM",
                "largest_city": "Kohima",
                "capital": "Kohima"
            },
            {
                "id": 75,
                "country": "IND",
                "name": "Odisha",
                "abbr": "OD",
                "area": "41974218SKM",
                "largest_city": "Bhubaneswar",
                "capital": "Bhubaneswar"
            },
            {
                "id": 76,
                "country": "IND",
                "name": "Punjab",
                "abbr": "PB",
                "area": "27743338SKM",
                "largest_city": "Chandigarh",
                "capital": "Chandigarh"
            },
            {
                "id": 77,
                "country": "IND",
                "name": "Rajasthan",
                "abbr": "RJ",
                "area": "68548437SKM",
                "largest_city": "Jaipur",
                "capital": "Jaipur"
            },
            {
                "id": 78,
                "country": "IND",
                "name": "Sikkim",
                "abbr": "SK",
                "area": "610577SKM",
                "largest_city": "Gangtok",
                "capital": "Gangtok"
            },
            {
                "id": 79,
                "country": "IND",
                "name": "Tamil Nadu",
                "abbr": "TN",
                "area": "72147030SKM",
                "largest_city": "Chennai",
                "capital": "Chennai"
            },
            {
                "id": 80,
                "country": "IND",
                "name": "Telangana",
                "abbr": "TS",
                "area": "3519397837SKM",
                "largest_city": "Hyderabad",
                "capital": "Hyderabad"
            },
            {
                "id": 81,
                "country": "IND",
                "name": "Tripura",
                "abbr": "TR",
                "area": "3673917SKM",
                "largest_city": "Agartala",
                "capital": "Agartala"
            },
            {
                "id": 82,
                "country": "IND",
                "name": "Uttar Pradesh",
                "abbr": "UP",
                "area": "199812341SKM",
                "largest_city": "Lucknow",
                "capital": "Lucknow"
            },
            {
                "id": 83,
                "country": "IND",
                "name": "Uttarakhand",
                "abbr": "UK",
                "area": "10086292SKM",
                "largest_city": "Dehradun",
                "capital": "Dehradun"
            },
            {
                "id": 84,
                "country": "IND",
                "name": "West Bengal",
                "abbr": "WB",
                "area": "91276115SKM",
                "largest_city": "Kolkata",
                "capital": "Kolkata"
            },
            {
                "id": 85,
                "country": "IND",
                "name": "Andaman and Nicobar Islands",
                "abbr": "AN",
                "area": "8249SKM",
                "largest_city": "Port Blair",
                "capital": "Port Blair"
            },
            {
                "id": 86,
                "country": "IND",
                "name": "Chandigarh",
                "abbr": "CH",
                "area": "114SKM",
                "largest_city": "Chandigarh",
                "capital": "Chandigarh"
            },
            {
                "id": 87,
                "country": "IND",
                "name": "Dadra and Nagar Haveli",
                "abbr": "DN",
                "area": "491SKM",
                "largest_city": "Silvassa",
                "capital": "Silvassa"
            },
            {
                "id": 88,
                "country": "IND",
                "name": "Daman and Diu",
                "abbr": "DD",
                "area": "112SKM",
                "largest_city": "Daman",
                "capital": "Daman"
            },
            {
                "id": 89,
                "country": "IND",
                "name": "Delhi",
                "abbr": "DL",
                "area": "1490SKM",
                "largest_city": "New Delhi",
                "capital": "New Delhi"
            },
            {
                "id": 56,
                "country": "IND",
                "name": "Andhra Pradesh",
                "abbr": "AP",
                "area": "49506799SKM",
                "largest_city": "Hyderabad Amaravati",
                "capital": "Hyderabad Amaravati"
            },
            {
                "id": 57,
                "country": "IND",
                "name": "Arunachal Pradesh",
                "abbr": "AR",
                "area": "1383727SKM",
                "largest_city": "Itanagar",
                "capital": "Itanagar"
            },
            {
                "id": 58,
                "country": "IND",
                "name": "Assam",
                "abbr": "AS",
                "area": "31205576SKM",
                "largest_city": "Dispur",
                "capital": "Dispur"
            },
            {
                "id": 59,
                "country": "IND",
                "name": "Bihar",
                "abbr": "BR",
                "area": "104099452SKM",
                "largest_city": "Patna",
                "capital": "Patna"
            },
            {
                "id": 60,
                "country": "IND",
                "name": "Chhattisgarh",
                "abbr": "CG",
                "area": "25545198SKM",
                "largest_city": "Naya Raipur",
                "capital": "Naya Raipur"
            },
            {
                "id": 61,
                "country": "IND",
                "name": "Goa",
                "abbr": "GA",
                "area": "1458545SKM",
                "largest_city": "Panaji",
                "capital": "Panaji"
            },
            {
                "id": 62,
                "country": "IND",
                "name": "Gujarat",
                "abbr": "GJ",
                "area": "60439692SKM",
                "largest_city": "Gandhinagar",
                "capital": "Gandhinagar"
            },
            {
                "id": 63,
                "country": "IND",
                "name": "Haryana",
                "abbr": "HR",
                "area": "25351462SKM",
                "largest_city": "Chandigarh",
                "capital": "Chandigarh"
            },
            {
                "id": 64,
                "country": "IND",
                "name": "Himachal Pradesh",
                "abbr": "HP",
                "area": "6864602SKM",
                "largest_city": "Shimla Dharamshala",
                "capital": "Shimla Dharamshala"
            },
            {
                "id": 90,
                "country": "IND",
                "name": "Lakshadweep",
                "abbr": "LD",
                "area": "32SKM",
                "largest_city": "Kavaratti",
                "capital": "Kavaratti"
            },
            {
                "id": 91,
                "country": "IND",
                "name": "Puducherry",
                "abbr": "PY",
                "area": "492SKM",
                "largest_city": "Pondicherry",
                "capital": "Pondicherry"
            }
        ]
    },
    getters,
    mutations,
    actions
})