<template>
  <div class="user" v-if="currentUser">
    <div class="photo">
      <img :src="`https://ui-avatars.com/api/?background=FFF&color=6BCF98&rounded=true&name=${currentUser.name}`">
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
        <span>
          {{ currentUser.name }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <router-link :to="`/admin/profile/${currentUser.profile_id}`">
                <span class="sidebar-mini-icon">Mp</span>
                <span class="sidebar-normal">My Profile</span>
              </router-link>
            </li>
            <li>
              <router-link to="/logout">
                <span class="sidebar-mini-icon">L</span>
                <span class="sidebar-normal">Logout</span>
              </router-link>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  components: {
    CollapseTransition
  },
  data() {
    return {
      isClosed: true
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser"
    })
  },
  mounted(){
    // this.$store.dispatch('fetchAuthenticatedUser')
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    }
  }
};
</script>
<style scoped>
.nav.nav-menu {
  margin-top: 0;
}
</style>
