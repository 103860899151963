import axios from 'axios'
import router from 'src/routes/router'

var instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
    headers: {
        'Content-type': 'application/json',
    }
});

export default {

    fetchAuthenticatedUser (){
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        // console.log('token', authToken)
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get('/auth/user', headers)
        .then(response => response.data)
        
    },

    fetchStats (){
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        // console.log('token', authToken)
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get('/auth/stats', headers)
        .then(response => response.data)
    },

    /* locations BREAD */
    // Browse
    fetchLocationsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/locations`, headers)
        .then(response => response.data)
    },

    fetchLocations (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/locations/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchLocation (loc_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/location/${loc_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createLocation (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/location', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateLocation (loc_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/location/${loc_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteLocation (loc_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/location/${loc_id}`, headers)
        .then(response => response.data.data)
    },
    /* locations BREAD End */

    /* products BREAD */
    // Browse
    fetchProductsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/products`, headers)
        .then(response => response.data)
    },
    fetchProducts (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/products/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchProduct (product_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/product/${product_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createProduct (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/product', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateProduct (product_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/product/${product_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteProduct (product_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/product/${product_id}`, headers)
        .then(response => response.data.data)
    },
    /* products BREAD End */


    /* inventory BREAD */
    // Browse
    fetchInventories (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productspivots/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchInventory (inventory_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productspivot/${inventory_id}`, headers)
        .then(response => response.data.data)
    },
    fetchInventoryByLocation (location_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productspivot/${location_id}`, headers)
        .then(response => response.data.data)
    },
    fetchInventoryByProduct (product_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productspivot/${product_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createInventory (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/productspivot', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateInventory (inventory_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/productspivot/${inventory_id}`, payload, headers)
        .then(response => response.data.data)
    },
    returnInventory (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/productspivotreturn`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteInventory (inventory_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/productspivot/${inventory_id}`, headers)
        .then(response => response.data.data)
    },
    /* inventory BREAD End */


    /* prescriptions BREAD */
    // Browse
    fetchPrescriptionsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/prescriptions`, headers)
        .then(response => response.data)
    },
    fetchPrescriptions (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/prescriptions/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchPrescription (prescription_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/prescription/${prescription_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createPrescription (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/prescription', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updatePrescription(prescription_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/prescription/${prescription_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deletePrescription (prescription_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/prescription/${prescription_id}`, headers)
        .then(response => response.data.data)
    },
    /* prescriptions BREAD End */


    /* product_types BREAD */
    // Browse
    fetchProductTypesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/product-types`, headers)
        .then(response => response.data)
    },
    fetchProductTypes (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/product-types/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchProductType (type_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/product-type/${type_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createProductType (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/product_type', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateProductType (type_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/product-type/${type_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteProductType (type_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/product-type/${type_id}`, headers)
        .then(response => response.data.data)
    },
    /* products BREAD End */

   

    fetchFrequencies () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}

        return instance
        .get(`/auth/frequencies`, headers)
        .then(response => response.data.data)
    },

    fetchComments () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}

        return instance
        .get(`/auth/comments`, headers)
        .then(response => response.data.data)
    },

    fetchDiscounts () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}

        return instance
        .get(`/auth/discounts`, headers)
        .then(response => response.data.data)
    },


    createBillingNew(payload){
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/billingnew', payload, headers)
        .then(response => response.data.data)
    },

    updateBillingNew(bill_id, payload){
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/billingnew/${bill_id}`, payload, headers)
        .then(response => response.data.data)
    },

    /* billings BREAD */
    // Browse
    fetchBillingsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/billings`, headers)
        .then(response => response.data)
    },
    fetchBillings (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/billings/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchBilling (bill_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/billing/${bill_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createBilling(payload){
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/billing', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateBilling(bill_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/billing/${bill_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteBilling (bill_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/billing/${bill_id}`, headers)
        .then(response => response.data.data)
    },
    /* billings BREAD End */


    /** Employee BREAD starts */
    // Browse
    fetchEmployeesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/employees`, headers)
        .then(response => response.data)
    },
    fetchEmployees (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/employees/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchEmployee (emp_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/employee/${emp_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createEmployee (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/employees', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateEmployee (emp_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/employee/${emp_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteEmployee (emp_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/employee/${emp_id}`, headers)
        .then(response => response.data.data)
    },
    /* employee BREAD End */


    /** patient BREAD starts */
    // Browse
    fetchPatientsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/patients`, headers)
        .then(response => response.data)
    },
    fetchSimplePatientsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/patients/simple`, headers)
        .then(response => response.data)
    },
    fetchPatients (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/patients/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchPatient (pat_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/patient/${pat_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createPatient (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/patient', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updatePatient (pat_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/patient/${pat_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deletePatient (pat_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/patient/${pat_id}`, headers)
        .then(response => response.data.data)
    },
    /* patient BREAD End */


    /** lead BREAD starts */
    // Browse
    fetchLeadsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/leads`, headers)
        .then(response => response.data)
    },
    fetchLeads (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/leads/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchLead (lead_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/lead/${lead_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createLead (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/lead', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateLead (lead_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/lead/${lead_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteLead (lead_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/lead/${lead_id}`, headers)
        .then(response => response.data.data)
    },
    // converty
    convertLead (lead_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/leadtopatient/${lead_id}`, headers)
        .then(response => response.data.data)
    },
    /* lead BREAD End */



    /** stock transfer BREAD starts */
    // Browse
    fetchTransfersAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/stocktransfer`, headers)
        .then(response => response.data)
    },
    fetchTransfers (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/stocktransfer/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchTransfer (transfer_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/stocktransfer/${transfer_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createTransfer (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/stocktransfer', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateTransfer (transfer_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/stocktransfer/${transfer_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteTransfer (transfer_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/stocktransfer/${transfer_id}`, headers)
        .then(response => response.data.data)
    },
    /* stock transfer BREAD End */


    /** settings BREAD starts */
    // Browse
    fetchSettingsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/settings`, headers)
        .then(response => response.data)
    },
    fetchSettings (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/settings/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchSetting (setting_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/setting/${setting_id}`, headers)
        .then(response => response.data.data)
    },
    fetchSettingByKey (key) {
        let headers = {headers: {
            'Content-Type': 'application/json',
        }}
        return instance
        .get(`/setting/${key}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createSetting (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/settings', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateSetting (setting_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/setting/${setting_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteSetting (setting_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/setting/${setting_id}`, headers)
        .then(response => response.data.data)
    },
    /* settings BREAD End */


     /** images BREAD starts */
    // Browse
    fetchImagesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/settingsimages`, headers)
        .then(response => response.data)
    },
    fetchImages (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/settingsimages/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchImage (image_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/settingsimage/${image_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createImage (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/settingsimage', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateImage (image_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/settingsimage/${image_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteSettingsImage (image_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/settingsimage/${image_id}`, headers)
        .then(response => response.data.data)
    },
    /* images BREAD End */



    /** dosages BREAD starts */
    // Browse
    fetchDosagesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/dosages`, headers)
        .then(response => response.data)
    },
    fetchDosages (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/dosages/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchDosage(dosage_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/dosage/${dosage_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createDosage (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/dosage', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateDosage (dosage_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/dosage/${dosage_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteDosage (dosage_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/dosage/${dosage_id}`, headers)
        .then(response => response.data.data)
    },
    /* dosages BREAD End */


    /** frequencies BREAD starts */
    // Browse
    fetchFrequenciesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/frequencies`, headers)
        .then(response => response.data)
    },
    fetchFrequencies (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/frequencies/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchFrequency(frequency_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/frequency/${frequency_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createFrequency (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/frequency', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateFrequency (frequency_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/frequency/${frequency_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteFrequency (frequency_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/frequency/${frequency_id}`, headers)
        .then(response => response.data.data)
    },
    /* frequencies BREAD End */

    /** comments BREAD starts */
    // Browse
    fetchCommentsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/comments`, headers)
        .then(response => response.data)
    },
    fetchComments (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/comments/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchComment(comment_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/comment/${comment_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createComment (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/comment', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateComment (comment_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/comment/${comment_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteComment (comment_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/comment/${comment_id}`, headers)
        .then(response => response.data.data)
    },
    /* comments BREAD End */

    /** productTypes BREAD starts */
    // Browse
    fetchProductTypesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productTypes`, headers)
        .then(response => response.data)
    },
    fetchProductTypes (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productTypes/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchProductType(productType_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/productType/${productType_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createProductType (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/productType', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateProductType (productType_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/productType/${productType_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteProductType (productType_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/productType/${productType_id}`, headers)
        .then(response => response.data.data)
    },
    /* productTypes BREAD End */


    /** discounts BREAD starts */
    // Browse
    fetchDiscountsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/discounts`, headers)
        .then(response => response.data)
    },
    fetchDiscounts (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/discounts/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchDiscount(discount_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/discount/${discount_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createDiscount (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/discount', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateDiscount (discount_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/discount/${discount_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteDiscount (discount_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/discount/${discount_id}`, headers)
        .then(response => response.data.data)
    },
    /* discounts BREAD End */


    /** roles BREAD starts */
    // Browse
    fetchRolesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/roles`, headers)
        .then(response => response.data)
    },
    fetchRoles (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/roles/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchRole(role_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/role/${role_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createRole (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/role', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateRole (role_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/role/${role_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteRole (role_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/role/${role_id}`, headers)
        .then(response => response.data.data)
    },
    /* roles BREAD End */


    /** recordTypes BREAD starts */
    // Browse
    fetchRecordTypesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/recordTypes`, headers)
        .then(response => response.data)
    },
    fetchRecordTypes (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/recordTypes/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchRecordType(recordType_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/recordType/${recordType_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createRecordType (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/recordType', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateRecordType (recordType_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/recordType/${recordType_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteRecordType (recordType_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/recordType/${recordType_id}`, headers)
        .then(response => response.data.data)
    },
    /* recordTypes BREAD End */


    /** records BREAD starts */
    // Browse
    fetchRecordsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/records`, headers)
        .then(response => response.data)
    },
    fetchRecords (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/records/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchRecord(record_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/record/${record_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createRecord (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/record', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateRecord (record_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/record/${record_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteRecord (record_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/record/${record_id}`, headers)
        .then(response => response.data.data)
    },
    /* records BREAD End */

    /** GSTTypes BREAD starts */
    // Browse
    fetchGSTTypesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/GSTTypes`, headers)
        .then(response => response.data)
    },
    fetchGSTTypes (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/GSTTypes/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchGSTType(GSTType_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/GSTType/${GSTType_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createGSTType (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/GSTType', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateGSTType (GSTType_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/GSTType/${GSTType_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteGSTType (GSTType_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/GSTType/${GSTType_id}`, headers)
        .then(response => response.data.data)
    },
    /* GSTTypes BREAD End */


    /** modes BREAD starts */
    // Browse
    fetchModesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/modeofpayments`, headers)
        .then(response => response.data)
    },
    fetchModes (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/modeofpayments/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchMode(mode_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/modeofpayment/${mode_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createMode (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/modeofpayment', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateMode (mode_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/modeofpayment/${mode_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteMode (mode_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/modeofpayment/${mode_id}`, headers)
        .then(response => response.data.data)
    },
    /* modes BREAD End */


    /** Appointments BREAD starts */
    // Browse
    fetchAppointmentsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/appointments`, headers)
        .then(response => response.data)
    },
    fetchAppointments (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/appointments/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },

    fetchAppointmentsByDoctor (per_page, page, searchQuery, doctor) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/appointments/${per_page}?page=${page}&searchQuery=${searchQuery}&doctor=${doctor}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchAppointment(appointment_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/appointment/${appointment_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createAppointment (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/appointment', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateAppointment (appointment_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/appointment/${appointment_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteAppointment (appointment_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/appointment/${appointment_id}`, headers)
        .then(response => response.data.data)
    },
    /* Appointments BREAD End */



    /** Events BREAD starts */
    // Browse
    fetchEventsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/events`, headers)
        .then(response => response.data)
    },
    fetchEvents (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/events/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchEvent(event_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/event/${event_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createEvent (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/event', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateEvent (event_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/event/${event_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteEvent (event_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/event/${event_id}`, headers)
        .then(response => response.data.data)
    },
    /* Events BREAD End */

    /** Infos BREAD starts */
    // Browse
    fetchInfosAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/infos`, headers)
        .then(response => response.data)
    },
    fetchInfos (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/infos/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchInfo(info_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/info/${info_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createInfo (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/info', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateInfo (info_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/info/${info_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteInfo (info_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/info/${info_id}`, headers)
        .then(response => response.data.data)
    },
    /* Infos BREAD End */



    /** Doctor BREAD starts */
    // Browse
    fetchDoctorsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/doctors`, headers)
        .then(response => response.data)
    },
    fetchDoctors (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/doctors/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchDoctor (emp_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/doctors/${emp_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createDoctor (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/doctors', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateDoctor (emp_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/doctor/${emp_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteDoctor (emp_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/doctor/${emp_id}`, headers)
        .then(response => response.data.data)
    },
    /* doctor BREAD End */


    deleteImage(payload){
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/deleteimage', payload, headers)
        .then(response => response.data.data)
    },

    /** Disease BREAD starts */
    // Browse
    fetchDiseasesAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/diseases`, headers)
        .then(response => response.data)
    },
    fetchDiseases (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/diseases/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchDisease (emp_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/diseases/${emp_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createDisease (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/disease', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateDisease (emp_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/disease/${emp_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteDisease (emp_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/disease/${emp_id}`, headers)
        .then(response => response.data.data)
    },
    /* disease BREAD End */

    fetchCountries(){
        let headers = {headers: {
            'Content-Type': 'application/json',
        }}
        return instance
        // .get(`https://restcountries.eu/rest/v2/all?fields=name;`, headers)
        .get(`https://countriesnow.space/api/v0.1/countries/states`, headers)
        // .then(res => console.log(res.data.data))
        .then(response => response.data.data)
    },

    /** Payments BREAD starts */
    // Browse
    fetchPaymentsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/payments`, headers)
        .then(response => response.data)
    },
    fetchPayments (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/payments/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchPayment(Payment_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/payment/${Payment_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createPayment (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/payment', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updatePayment (Payment_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/payment/${Payment_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deletePayment (Payment_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/payment/${Payment_id}`, headers)
        .then(response => response.data.data)
    },
    /* Payments BREAD End */


    /** Consultations BREAD starts */
    // Browse
    fetchConsultationsAll () {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/consultations`, headers)
        .then(response => response.data)
    },
    fetchConsultations (per_page, page, searchQuery) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/consultations/${per_page}?page=${page}&searchQuery=${searchQuery}`, headers)
        .then(response => response.data)
    },
    // Read
    fetchConsultation(consultation_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/consultation/${consultation_id}`, headers)
        .then(response => response.data.data)
    },
    // Add
    createConsultation (payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post('/auth/consultation', payload, headers)
        .then(response => response.data.data)
    },
    // Edit
    updateConsultation (consultation_id, payload) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .post(`/auth/consultation/${consultation_id}`, payload, headers)
        .then(response => response.data.data)
    },
    // Delete
    deleteConsultation (consultation_id) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .delete(`/auth/consultation/${consultation_id}`, headers)
        .then(response => response.data.data)
    },
    // Check Consultation
    checkConsultation (patient_id, location_id = 0) {
        let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
        let headers = {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }}
        return instance
        .get(`/auth/consultationFind/${patient_id}/${location_id}`, headers)
        .then(response => response.data)
    },
    /* Consultations BREAD End */
}