<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">

        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#">Zyro ERP Solution</a>
    </div>

    <template slot="navbar-menu">
      <form v-if="false">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form>
      <ul class="navbar-nav">
        <li class="nav-item" v-if="false">
          <a class="nav-link btn-magnify" href="#pablo">
            <i class="nc-icon nc-layout-11"></i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <drop-down icon="nc-icon nc-bell-55" tag="li"  v-if="notifications.length > 0"
                   position="right"
                   direction="none"
                   class="nav-item btn-rotate dropdown">
          <a slot="title"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen">
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Notifications</span>
            </p>
          </a>
          <a class="dropdown-item" href="#" v-for="(notification, index) in notifications" :key="index">
            {{ notification.appointment_id }} - <u>{{ notification.patient.name }}</u> with <u>{{ notification.doctor.name }}</u> at <b>{{ notification.location.name }}</b> on <small>{{ formattedDate(notification.datetime) }}</small>
          </a>
        </drop-down>
        <li class="nav-item" v-if="false">
          <a class="nav-link btn-rotate" href="#pablo">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import { mapGetters } from "vuex";

  export default {
    components: {
      Navbar,
      NavbarToggleButton
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false
      }
    },
    mounted(){
      this.$sidebar.toggleMinimize()
      this.$store.dispatch("fetchAppointmentsAll");
      setInterval(() => {
        this.$store.dispatch("fetchAppointmentsAll");
      }, 1 * 60 * 1000)
    },
    computed: {
      ...mapGetters({
          appointments: "getAppointments",
      }),
      notifications(){
        let notifications = []
        if(this.appointments)
        this.appointments.forEach(appointment => {
          let ONE_HOUR = 60 * 60 * 1000; /* ms */
          let datetime = new Date(appointment.datetime)
          let now = new Date()

          // console.log('Hours: ',(ONE_HOUR * 2));
          // console.log('Date', (datetime));
          // console.log('Diff', (datetime - now));
          

          if(((datetime - now) < (ONE_HOUR * 2)) && ((datetime - now) > 0)){
            console.log('sent notification');
            
            // less than 2 HRS
            this.$notification.show('Appointment Alert from Zyro ERP', {
                body: `${appointment.appointment_id } - ${appointment.patient.name} with ${appointment.doctor.name} at ${appointment.location.name } on ${this.formattedDate(appointment.datetime)}`,
                icon: '/static/img/zyro-logo.png',
                badge: '/static/img/zyro-logo.png'
            }, {
              onclick: function () {
                  window.location.href = `/appointments/manage/${appointment.id}`
              },
            })
          }

          if(((datetime - now) < (ONE_HOUR * 8)) && ((datetime - now) > 0)){
            // less than 8 HRS
            notifications.push(appointment)
          }

          

        });
        return notifications;
      }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      formattedDate(date, isTime = true){
          var d = new Date(date);
          let options = (isTime) ? { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' } : { year: 'numeric', month: 'long', day: 'numeric' } ;
          return  d.toLocaleDateString('en-US', options); 
      }
    }
  }

</script>
<style>

</style>
