<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications transition-name="notification-list" transition-mode="out-in"></notifications>
    <loading :active="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
// Loading some plugin css asynchronously
import "sweetalert2/dist/sweetalert2.css";
import "vue-notifyjs/themes/default.css";

import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Loading
  },
  computed: {
    ...mapGetters({
      isLoading: "getIsLoading"
    }),
  },
  mounted() {
    this.$store.dispatch("fetchGender");
    this.$store.dispatch("fetchMaritalStatus");
    this.$store.dispatch("fetchReligion");
    this.$store.dispatch("fetchBloodGroup");
    this.$store.dispatch("fetchAppointmentType");
    this.$store.dispatch("fetchCountries");
  }
};
</script>
<style lang="scss">
.notifications.vue-notifyjs .alert{
  z-index: 1100!important;
}
</style>
