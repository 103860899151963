<template>
  <div class="login-page">
    <!-- <app-navbar></app-navbar> -->
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
              <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>

                  <fg-input
                    name="email" 
                    v-model="form.email" 
                    addon-left-icon="nc-icon nc-single-02" 
                    placeholder="Email Address" 
                    v-validate="modelValidations.email"
                    :error="getError('email')"
                    type="email"></fg-input>

                  <fg-input 
                    name="password"
                    v-model="form.password" 
                    addon-left-icon="nc-icon nc-key-25" 
                    placeholder="Password" 
                    v-validate="modelValidations.password"
                    :error="getError('password')"
                    type="password"></fg-input>

                  <p-button slot="footer" type="success" round block class="mb-3" @click.prevent="validate">Login</p-button>
                </card>
              </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Card, Button} from 'src/components/UIComponents';
  // import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  export default {
    components: {
      Card,
      // AppNavbar,
      AppFooter,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          // console.log(isValid)
          if(isValid)
            this.$store.dispatch('login', {email: this.form.email, password: this.form.password})
            
        })
      }
    },
    data() {
      return {
        form: {
          email: '',
          password: ''
        },
        modelValidations: {
          email: {
            required: true,
            email: true
          },
          password: {
            required: true,
            min: 5
          }
        }
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
