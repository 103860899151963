
export default {
    getPatients: state => {
        return state.patients;
    },

    getPatient: state => {
        return state.patient;
    },

    getLeads: state => {
        return state.leads;
    },

    getLead: state => {
        return state.lead;
    },

    getLocations: state => {
        return state.locations;
    },

    getLocation: state => {
        return state.location;
    },

    getEmployees: state => {
        return state.employees;
    },

    getEmployee: state => {
        return state.employee;
    },

    getDoctors: state => {
        return state.doctors;
    },

    getDoctor: state => {
        return state.doctor;
    },

    getRoles: state => {
        return state.roles;
    },
    
    getCurrentUser(state){
        return state.currentUser;
    },

    getStats(state){
        return state.stats;
    },

    getUserToken(state){
        let token = localStorage.getItem('zyro_user_token')
        let authToken = (token) ? JSON.parse(token).access_token : null;
        return (authToken);
    },

    getIsLoggedIn(state){
        return state.isLoggedIn;
    },

    getIsLoading(state){
        return state.isLoading;
    },

    getPagination(state){
        return state.pagination;
    },

    getPrescriptionItems(state){
        return state.prescription_items;
    },

    getProductItems(state){
        return state.product_items;
    },

    getProducts(state){
        return state.products;
    },

    getProduct(state){
        return state.product;
    },

    getInventories(state){
        return state.inventories;
    },

    getInventory(state){
        return state.inventory;
    },

    getProductTypes(state){
        return state.product_types;
    },

    getProductType(state){
        return state.product_type;
    },

    getFrequencies(state){
        return state.frequencies;
    },

    getDosages(state){
        return state.dosages;
    },

    getComments(state){
        return state.comments;
    },

    getDiscounts(state){
        return state.discounts;
    },

    getRoles(state){
        return state.roles;
    },

    getPrescriptions(state){
        return state.prescriptions
    },

    getPrescription(state){
        return state.prescription
    },

    getBillings(state){
        return state.billings
    },

    getBilling(state){
        return state.billing
    },

    getGenders(state){
        return state.genders
    },

    getMaritalStatuses(state){
        return state.marital_statuses
    },

    getReligions(state){
        return state.religions
    },

    getBloodGroups(state){
        return state.blood_groups
    },

    getAppointmentTypes(state){
        return state.appointment_types
    },

    getTransfers(state){
        return state.transfers
    },

    getTransfer(state){
        return state.transfer
    },

    getSettings(state){
        return state.settings
    },

    getSetting(state){
        return state.setting
    },

    getImages(state){
        return state.images
    },

    getImage(state){
        return state.image
    },

    getDosages(state){
        return state.dosages
    },

    getDosage(state){
        return state.dosage
    },

    getFrequencies(state){
        return state.frequencies
    },

    getFrequency(state){
        return state.frequency
    },

    getComments(state){
        return state.comments
    },

    getComment(state){
        return state.comment
    },

    getProductTypes(state){
        return state.product_types
    },

    getProductType(state){
        return state.product_type
    },

    getDiscounts(state){
        return state.discounts
    },

    getDiscount(state){
        return state.discount
    },

    getRoles(state){
        return state.roles
    },

    getRole(state){
        return state.role
    },

    getRecordTypes(state){
        return state.record_types
    },

    getRecordType(state){
        return state.record_type
    },

    getRecords(state){
        return state.records
    },

    getGSTType(state){
        return state.gst_type
    },

    getGSTTypes(state){
        return state.gst_types
    },

    getRecord(state){
        return state.record
    },

    getModes(state){
        return state.modes
    },

    getMode(state){
        return state.mode
    },

    getAppointments(state){
        return state.appointments
    },

    getAppointment(state){
        return state.appointment
    },

    getEvents(state){
        return state.events
    },

    getEvent(state){
        return state.event
    },

    getInfos(state){
        return state.infos
    },

    getInfo(state){
        return state.info
    },

    getDiseases(state){
        return state.diseases
    },

    getDisease(state){
        return state.disease
    },

    getCountries(state){
        return state.countries
    },

    getStates(state){
        return state.states
    },

    getPayments(state){
        return state.payments
    },

    getPayment(state){
        return state.payment
    },

    getConsultations(state){
        return state.consultations
    },

    getConsultation(state){
        return state.consultation
    },

    getConsultationFee(state){
        return state.consultation_fee
    },

    getPatientConsultation(state){
        return state.patient_consultation
    }    

}