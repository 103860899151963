import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store/store'


// router setup

// import routes from './routes'
// import sidebarLinks from '../sidebarLinks'

import routes from './zyroRoutes'
import sidebarLinks from '../zyroSidebarLinks'

import { NProgress, routeResolved, tryInitProgress} from '../progressbar'

Vue.use(VueRouter)

// configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    
    if(to.matched.some(record => (record.name == 'Logout'))){
        store.commit('logout');
        next({
            path: '/login'
        });
    }
    else if(to.matched.some(record => (record.name == 'Login'))){
        if (localStorage.getItem('zyro_user_token') != null) {
            next({
                path: '/admin',
                params: { nextUrl: to.fullPath }
            });
        }
        else{
            next();
        }
    }
    else if (to.matched.some(record => record.meta.auth)) {
        // console.log('auth required');
        if (localStorage.getItem('zyro_user_token') == null) {
            // console.log('no tokens');
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            });
        }
        else{
            // console.log('tokens');
            store.dispatch('fetchAuthenticatedUser')
        }
    }
    else{
        // console.log('auth not required', to);
    }
    // tryInitProgress();
    return next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    // routeResolved = true;
    // NProgress.done();
});


export default router;