<template>
  <div class="card card-user">
    <!-- <div class="image">
      <img src="/static/img/background/damir-bosnjak.jpg" alt="...">
    </div> -->
    <div class="card-body">
      <div class="author">
        <a href="#">
          <!-- <img class="avatar border-gray" :src="`https://ui-avatars.com/api/?background=27ae60&color=ffffff&size=128&name=${name}`" alt="..."> -->
          <h5 class="title">{{ user.name }}</h5>
        </a>
        <p class="description">{{ user.patient_id }} <br> <small class="text-center">{{ desc }}</small> </p>
        <p class="badge badge-primary p-2" v-if="user.location">{{ user.location.location_id }} - {{ user.location.name }}</p>
      </div>
      <div class="table">
        <table class="table table-sm table-striped table-hover">
          <tr v-if="user.designation">
            <td class="text-left">Designation</td>
            <td class="text-right">{{ user.designation }}</td>
          </tr>
          <tr>
            <td class="text-left">Email</td>
            <td class="text-right"><a :href="`mailto:${user.email}`">{{ user.email }}</a></td>
          </tr>
          <tr>
            <td class="text-left">Phone</td>
            <td class="text-right"><a :href="`tel:${user.phone}`">{{ user.phone }}</a></td>
          </tr>
          <tr v-if="user.alt_phone">
            <td class="text-left">Alt Phone</td>
            <td class="text-right"><a :href="`tel:${user.alt_phone}`">{{ user.alt_phone }}</a></td>
          </tr>
          <tr v-if="user.languages">
            <td class="text-left">Languages</td>
            <td class="text-right">{{ user.languages.join(', ') }}</td>
          </tr>
          <tr v-if="user.ref_name">
            <td class="text-left">Ref Name</td>
            <td class="text-right">{{ user.ref_name }}</td>
          </tr>
          <tr v-if="user.ref_contact">
            <td class="text-left">Ref Contact</td>
            <td class="text-right"><a :href="`tel:${user.ref_contact}`">{{ user.ref_contact }}</a></td>
          </tr>
          <tr v-if="user.billing_address_line">
            <td class="text-left">Billing Address</td>
            <td class="text-right"><address>{{ billing_address }}</address></td>
          </tr>
          <tr v-if="user.shipping_address_line">
            <td class="text-left">Shipping Address</td>
            <td class="text-right"><address>{{shipping_address }}</address></td>
          </tr>
          <tr v-if="user.present_address_line">
            <td class="text-left">Present Address</td>
            <td class="text-right"><address>{{ present_address }}</address></td>
          </tr>
          <tr v-if="user.permanent_address_line">
            <td class="text-left">Permanent Address</td>
            <td class="text-right"><address>{{ permanent_address }}</address></td>
          </tr>
          <tr v-if="user.createdby">
            <td class="text-left">Created By</td>
            <td class="text-right">{{ user.createdby.employee_id }} - {{ user.createdby.name }}</td>
          </tr>
          <tr v-if="user.created_on">
            <td class="text-left">Created On</td>
            <td class="text-right">{{ created_on }}</td>
          </tr>
          <tr v-if="user.convertedby && !user.isLead">
            <td class="text-left">Converted By</td>
            <td class="text-right">{{ user.convertedby.employee_id }} - {{ user.convertedby.name }}</td>
          </tr>
          <tr v-if="user.converted_on && !user.isLead">
            <td class="text-left">Converted On</td>
            <td class="text-right">{{ converted_on }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card-footer text-center">
      <small>Known Ailments</small><br>
      <span class="label badge badge-primary m-1" v-for="(disease, index) in user.known_diseases" :key="index">{{ disease }}</span>
    </div>
  </div>
</template>
<script>
  import { Collapse, CollapseItem } from "src/components/UIComponents";
  export default {
    components: {
      Collapse,
      CollapseItem
    },
    props: {
      user: Object
    },
    computed: {
      desc(){
        let age =  Math.abs(new Date(Date.now() - new Date(this.user.dob).getTime()).getUTCFullYear() - 1970);
        return `${age} yrs, ${this.user.gender}`
      },
      created_on(){
        var d = new Date(this.user.created_on);
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
        return  d.toLocaleDateString('en-US', options); 
      },
      converted_on(){
        var d = new Date(this.user.converted_on);
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
        return  d.toLocaleDateString('en-US', options); 
      },
      shipping_address(){
        return `${this.user.shipping_address_line}, ${this.user.shipping_landmark}, ${this.user.shipping_state}, ${this.user.shipping_pin} - ${this.user.shipping_country}`
      },
      billing_address(){
        return `${this.user.billing_address_line}, ${this.user.billing_landmark}, ${this.user.billing_state}, ${this.user.billing_pin} - ${this.user.billing_country}`
      },
      permanent_address(){
        return `${this.user.permanent_address_line}, ${this.user.permanent_landmark}, ${this.user.permanent_state}, ${this.user.permanent_pin} - ${this.user.permanent_country}`
      },
      present_address(){
        return `${this.user.present_address_line}, ${this.user.present_landmark}, ${this.user.present_state}, ${this.user.present_pin} - ${this.user.present_country}`
      }
    }
  }

</script>
<style>
.card.card-user .author{
  margin-top: 15px;
}
</style>
