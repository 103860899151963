import router from '../routes/router'

export default {

    // to logout an authenticated user
    logout(state) {
        state.isLoggedin = false;
        localStorage.removeItem('zyro_user_token');
        state.currentUser = null;
    },

    // update patients list
    setPatients(state, patients) {
        state.patients = patients
    },

    // update patient 
    setPatient(state, patient) {
        state.patient = patient
    },

    // update leads list
    setLeads(state, leads) {
        state.leads = leads
    },

    // update lead
    setLead(state, lead) {
        state.lead = lead
    },

    // update locations list
    setLocations(state, locations) {
        state.locations = locations
    },

    // update location
    setLocation(state, location) {
        state.location = location
    },

    // update employees list
    setEmployees(state, employees) {
        state.employees = employees
    },

    // update employee
    setEmployee(state, employee) {
        state.employee = employee
    },

    // update doctors list
    setDoctors(state, doctors) {
        state.doctors = doctors
    },

    setDoctor(state, doctor) {
        state.doctor = doctor
    },

    // update products
    setProducts(state, products) {
        state.products = products
    },

    // update product
    setProduct(state, product) {
        state.product = product
    },

    // update inventory list
    setInventories(state, inventories) {
        state.inventories = inventories
    },

    // update inventory list
    setInventory(state, inventory) {
        state.inventory = inventory
    },

    // update roles list
    setRoles(state, roles) {
        state.roles = roles
    },

    // save user to localStorage
    setUserToken(state, token) {
        localStorage.setItem('zyro_user_token', token);
        state.isLoggedIn = true
        router.push('admin/overview');
    },

    // update status
    setStats(state, stats) {
        state.stats = stats;
    },
    
    // update currentUser
    setCurrentUser(state, user) {
        state.currentUser = user[0];
    },

    // update isLoading 
    setLoader(state, toggle) {
        state.isLoading = toggle;
    },

    // update pagination
    setPagination(state, pagination) {
        state.pagination = pagination;
    },

    setPrescriptionItems(state, items) {
        state.prescriptionItems = items
    },

    addPrescriptionItem(state) {
        let item = {
            prescription_id: null,
            product_id: null,
            product_name: null,
            frequency: null,
            dosage_1: null,
            dosage_2: null,
            dosage_3: null,
            comment: null,
            extra: null
        }
        state.prescription_items.push(item)
    },

    removePrescriptionItem(state, index) {
        state.prescription_items.splice(index, 1)
    },

    refreshPrescriptionItem(state) {
        state.prescription_items = [
            {
                prescription_id: null,
                product_id: null,
                product: null,
                frequency: null,
                dose_1: null,
                dose_2: null,
                dose_3: null,
                comment: null,
                extra: null,
                unit: 'no.s'
            }
        ]
    },

    setPrescriptions(state, prescriptions){
        state.prescriptions = prescriptions
    },

    setPrescription(state, prescription){
        state.prescription = prescription
    },

    setBillings(state, billings){
        state.billings = billings
    },

    setBilling(state, billing){
        state.billing = billing
    },

    setGender(state, genders){
        state.genders = genders
    },

    setMaritalStatus(state, marital_statuses){
        state.marital_statuses = marital_statuses
    },

    setReligion(state, religions){
        state.religions = religions
    },

    setBloodGroup(state, blood_groups){
        state.blood_groups = blood_groups
    },

    setConsultationFee(state, consultation_fee){
        state.consultation_fee = consultation_fee
    },

    setAppointmentType(state, appointment_types){
        state.appointment_types = appointment_types
    },

    setTransfers(state, transfers){
        state.transfers = transfers
    },

    setTransfer(state, transfer){
        state.transfer = transfer
    },

    setSettings(state, settings){
        state.settings = settings
    },

    setSetting(state, setting){
        state.setting = setting
    },

    setImages(state, images){
        state.images = images
    },

    setImage(state, image){
        state.image = image
    },

    setDosages(state, dosages){
        state.dosages = dosages
    },

    setDosage(state, dosage){
        state.dosage = dosage
    },

    setFrequencies(state, frequencies){
        state.frequencies = frequencies
    },

    setFrequency(state, frequency){
        state.frequency = frequency
    },

    setComments(state, comments){
        state.comments = comments
    },

    setComment(state, comment){
        state.comment = comment
    },

    setProductTypes(state, productTypes){
        state.product_types = productTypes
    },

    setProductType(state, product_type){
        state.product_type = product_type
    },

    setDiscounts(state, discounts){
        state.discounts = discounts
    },

    setDiscount(state, discount){
        state.discount = discount
    },

    setRoles(state, roles){
        state.roles = roles
    },

    setRole(state, role){
        state.role = role
    },

    setRecordTypes(state, record_types){
        state.record_types = record_types
    },

    setRecordType(state, record_type){
        state.record_type = record_type
    },

    setRecords(state, records){
        state.records = records
    },

    setRecord(state, record){
        state.record = record
    },

    setGSTType(state, gst_type){
        state.gst_type = gst_type
    },

    setGSTTypes(state, gst_types){
        state.gst_types = gst_types
    },

    setModes(state, modes){
        state.modes = modes
    },

    setMode(state, mode){
        state.mode = mode
    },

    setAppointments(state, appointments){
        state.appointments = appointments
    },

    setAppointment(state, appointment){
        state.appointment = appointment
    },

    setEvents(state, events){
        state.events = events
    },

    setEvent(state, event){
        state.event = event
    },

    setInfos(state, infos){
        state.infos = infos
    },

    setInfo(state, info){
        state.info = info
    },

    setDiseases(state, diseases){
        state.diseases = diseases
    },

    setDisease(state, disease){
        state.disease = disease
    },

    addProductItem(state) {
        let item = {
            product: {
                id: null,
                sale_price: 0,
                quantity: 0,
                product_type:{
                    name: '-'
                },
                gst_type: {
                    percentage: 0
                }
            },
            quantity: 1,
            discount: 1,
            amount: 0
        }
        state.product_items.push(item)
    },

    removeProductItem(state, index) {
        state.product_items.splice(index, 1)
    },

    refreshProductItem(state) {
        state.product_items = [
            {
                product: {
                    id: null,
                    sale_price: 0,
                    quantity: 0,
                    product_type:{
                        name: '-'
                    },
                    gst_type: {
                        percentage: 0
                    }
                },
                quantity: 1,
                discount: 1,
                amount: 0
            }
        ]
    },

    setCountries(state, countries){
        state.countries = countries
    },

    setPayments(state, payments){
        state.payments = payments
    },

    setPayment(state, payment){
        state.payment = payment
    },

    setConsultations(state, consultations){
        state.consultations = consultations
    },

    setConsultation(state, consultation){
        state.consultation = consultation
    },

    setPatientConsultation(state, data){
        state.patient_consultation = data
    }
}