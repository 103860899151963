import axios from 'axios'
import Vue from 'vue'
import router from '../routes/router'

import client from 'api-client'

var instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
  // headers: {'Content-type': 'application/x-www-form-urlencoded'}
});


function successToast(msg) {
  Vue.prototype.$notify({
    message: msg,
    icon: 'fa fa-check',
    horizontalAlign: 'right',
    verticalAlign: 'top',
    type: 'success'
  })
}

function errorToast(msg) {
  Vue.prototype.$notify({
    message: msg,
    icon: 'far fa-bell',
    horizontalAlign: 'right',
    verticalAlign: 'top',
    type: 'danger'
  })
}

export default {

    login({ commit, dispatch }, { email, password }) {
        commit('setLoader', true)
        instance.post('/oauth/token', {
            username: email,
            password,
            grant_type: process.env.VUE_APP_GRANT_TYPE,
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET
        })
            .then(res => {
                if (res.data) {
                    commit('setUserToken', JSON.stringify(res.data));
                    successToast('Login Successful!')
                    dispatch('fetchAuthenticatedUser')
                    commit('setLoader', false)
                }
            })
            .catch(err => {
                // console.log("TCL: login -> err", err)
                errorToast(err.response.data.message)
                // console.log(err.response.data)
                commit('setLoader', false)
            })
    },

    fetchAuthenticatedUser({ commit }) {
        return client
            .fetchAuthenticatedUser()
            .then(user => commit('setCurrentUser', user))
            .catch(err => {
                console.log('Error : ',err)
                if(err.response.statusText == "Unauthorized"){
                    errorToast('You session has expired please login again!')
                    console.log('log')
                    router.push('/logout')
                }
            })
    },

    fetchStats({ commit }) {
        return client
            .fetchStats()
            .then(response => commit('setStats', response.data))
    },
    

    fetchLeads({ commit }) {
        return client
            .fetchLeads()
            .then(leads => commit('setLeads', leads))
    },


    fetchLocationsAll({ commit }) {

        return client
            .fetchLocationsAll()
            .then(locations => {
                commit('setLocations', locations.data)
            })
    },
    
    fetchLocations({ commit }, payload) {
        return client
            .fetchLocations(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(locations => {
                commit('setLocations', locations.data)
                commit('setPagination', locations.meta)
            })
    },

    fetchLocation({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchLocation(payload)
            .then(location => {
                commit('setLocation', location)
                commit('setLoader', false)
            })
    },

    createLocation({ commit }, payload) {
        commit('setLoader', true)
        return client
            .createLocation(payload)
            .then(response => {
                successToast('Location created Successfully!')
                router.push('/locations/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateLocation({ commit }, payload) {
        commit('setLoader', true)
        return client
            .updateLocation(payload.id, payload)
            .then(response => {
                successToast('Location updated Successfully!')
                router.push('/locations/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteLocation({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteLocation(payload)
            .then(response => {
                successToast('Location deleted Successfully!')
                router.push('/locations/list')
                dispatch('fetchLocations', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchProductsAll({ commit }) {

        return client
            .fetchProductsAll()
            .then(response => {
                commit('setProducts', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchProducts({ commit }, payload) {

        return client
            .fetchProducts(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setProducts', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchProduct({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchProduct(payload)
            .then(response => {
                commit('setProduct', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createProduct({ commit }, payload) {
        commit('setLoader', true)
        return client
            .createProduct(payload)
            .then(response => {
                successToast('Product created Successfully!')
                router.push('/products/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateProduct({ commit }, payload) {
        commit('setLoader', true)
        return client
            .updateProduct(payload.id, payload)
            .then(response => {
                successToast('Product updated Successfully!')
                router.push('/products/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteProduct({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteProduct(payload)
            .then(response => {
                successToast('Product deleted Successfully!')
                router.push('/products/list')
                dispatch('fetchProducts', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchInventories({ commit }, payload) {

        return client
            .fetchInventories(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setInventories', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchInventory({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchInventory(payload)
            .then(response => {
                commit('setInventory', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createInventory({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createInventory(payload)
            .then(response => {
                successToast('Inventory created Successfully!')
                dispatch('fetchInventories', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateInventory({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateInventory(payload.id, payload)
            .then(response => {
                successToast('Inventory updated Successfully!')
                dispatch('fetchInventories', {})
                router.push('/inventory/manage')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    returnInventory({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .returnInventory(payload)
            .then(response => {
                successToast('Inventory updated Successfully!')
                dispatch('fetchInventories', {})
                router.push('/inventory/manage')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteInventory({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteInventory(payload)
            .then(response => {
                successToast('Inventory deleted Successfully!')
                router.push('/inventory/manage')
                dispatch('fetchInventories', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchPrescriptionsAll({ commit }) {

        return client
            .fetchPrescriptionsAll()
            .then(response => {
                commit('setProducts', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchPrescriptions({ commit }, payload) {

        return client
            .fetchPrescriptions(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setPrescriptions', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchPrescription({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchPrescription(payload)
            .then(response => {
                commit('setPrescription', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createPrescription({ commit }, payload) {
        commit('setLoader', true)
        return client
            .createPrescription(payload)
            .then(response => {
                successToast('Prescription created Successfully!')
                commit('refreshPrescriptionItem')
                router.push('/prescriptions/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updatePrescription({ commit }, payload) {
        commit('setLoader', true)
        return client
            .updatePrescription(payload.id, payload)
            .then(response => {
                successToast('Prescription updated Successfully!')
                router.push('/prescriptions/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deletePrescription({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deletePrescription(payload)
            .then(response => {
                successToast('Prescription deleted Successfully!')
                router.push('/prescriptions/list')
                dispatch('fetchPrescriptions', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    createBillingNew({ commit }, payload){
        commit('setLoader', true)
        return client
            .createBillingNew(payload)
            .then(response => {
                successToast('Bill created Successfully!')
                commit('setLoader', false)
                router.push(`/billings/edit/${response.id}`)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateBillingNew({ commit }, payload){
        commit('setLoader', true)
        return client
            .updateBillingNew(payload.id, payload)
            .then(response => {
                successToast('Bill updated Successfully!')
                // router.push('/billings/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchBillingsAll({ commit }) {

        return client
            .fetchBillingsAll()
            .then(response => {
                commit('setBillings', response.data)
            })
    },
    
    fetchBillings({ commit }, payload) {

        return client
            .fetchBillings(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setBillings', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchBilling({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchBilling(payload)
            .then(response => {
                commit('setBilling', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createBilling({ commit }, payload){
        commit('setLoader', true)
        return client
            .createBilling(payload)
            .then(response => {
                successToast('Bill created Successfully!')
                commit('setLoader', false)
                router.push(`/billings/edit/${response.id}`)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateBilling({ commit }, payload) {
        commit('setLoader', true)
        return client
            .updateBilling(payload.id, payload)
            .then(response => {
                successToast('Billing updated Successfully!')
                // router.push('/billings/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteBilling({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteBilling(payload)
            .then(response => {
                successToast('Billing deleted Successfully!')
                router.push('/billings/list')
                dispatch('fetchBillings', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchConsultationsAll({ commit }) {

        return client
            .fetchConsultationsAll()
            .then(response => {
                commit('setConsultations', response.data)
            })
    },
    
    fetchConsultations({ commit }, payload) {

        return client
            .fetchConsultations(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setConsultations', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchConsultation({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchConsultation(payload)
            .then(response => {
                commit('setConsultation', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createConsultation({ commit }, payload){
        commit('setLoader', true)
        return client
            .createConsultation(payload)
            .then(response => {
                successToast('Bill created Successfully!')
                commit('setLoader', false)
                router.push(`/consultations/manage`)
                dispatch('fetchConsultations', {})
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateConsultation({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateConsultation(payload.id, payload)
            .then(response => {
                successToast('Consultation updated Successfully!')
                dispatch('fetchConsultations', {})
                router.push('/consultations/manage')
                commit('setLoader', false)


            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteConsultation({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteConsultation(payload)
            .then(response => {
                successToast('Consultation deleted Successfully!')
                router.push('/consultations/manage')
                dispatch('fetchConsultations', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    checkConsultation({ commit }, payload) {

        return client
            .checkConsultation(payload.patient_id, payload.location_id)
            .then(response => {
                commit('setPatientConsultation', response)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    



    fetchProductTypesAll({ commit }) {

        return client
            .fetchProductTypesAll()
            .then(response => {
                commit('setProductTypes', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchProductTypes({ commit }, payload) {

        return client
            .fetchProductTypes(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setProductTypes', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchProductType({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchProductType(payload)
            .then(response => {
                commit('setProductType', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createProductType({ commit }, payload) {
        commit('setLoader', true)
        return client
            .createProductType(payload)
            .then(response => {
                successToast('Product Type created Successfully!')
                router.push('/product-types/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateProductType({ commit }, payload) {
        commit('setLoader', true)
        return client
            .updateProductType(payload.id, payload)
            .then(response => {
                successToast('Product Type updated Successfully!')
                router.push('/product-types/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteProductType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteProductType(payload)
            .then(response => {
                successToast('Product Type deleted Successfully!')
                router.push('/product-types/list')
                dispatch('fetchProductTypes', {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },




    // fetchDoctors({ commit }) {
    //     return client
    //         .fetchDoctors()
    //         .then(doctors => commit('setDoctors', doctors))
    // },

    
    // fetchFrequencies({ commit }){
    //     commit('setLoader', true)
    //     return client
    //         .fetchFrequencies()
    //         .then(response => {
    //             commit('setFrequencies', response)
    //             commit('setLoader', false)
    //         })
    // },

    // fetchDosages({ commit }){
    //     commit('setLoader', true)
    //     return client
    //         .fetchDosages()
    //         .then(response => {
    //             commit('setDosages', response)
    //             commit('setLoader', false)
    //         })
    // },

    // fetchComments({ commit }){
    //     commit('setLoader', true)
    //     return client
    //         .fetchComments()
    //         .then(response => {
    //             commit('setComments', response)
    //             commit('setLoader', false)
    //         })
    // },

    // fetchDiscounts({ commit }){
    //     commit('setLoader', true)
    //     return client
    //         .fetchDiscounts()
    //         .then(response => {
    //             commit('setDiscounts', response)
    //             commit('setLoader', false)
    //         })
    // },



    fetchEmployeesAll({
        commit
      }) {
    
        return client
          .fetchEmployeesAll()
          .then(employees => {
            commit('setEmployees', employees.data)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchEmployees({
        commit
      }, payload) {
    
        return client
          .fetchEmployees(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
          .then(employees => {
            commit('setEmployees', employees.data)
            commit('setPagination', employees.meta)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchEmployee({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .fetchEmployee(payload)
          .then(employee => {
            commit('setEmployee', employee)
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      createEmployee({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .createEmployee(payload)
          .then(response => {
            successToast('Employee created Successfully!')
            if (response.designation == 'doctor') {
              router.push('/doctors/list');
            } else {
              router.push('/employees/list');
            }
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      updateEmployee({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .updateEmployee(payload.id, payload)
          .then(response => {
            successToast('Employee updated Successfully!')
            if (response.designation == 'doctor') {
              router.push('/doctors/list');
            } else {
              router.push('/employees/list');
            }
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      deleteEmployee({
        commit,
        dispatch
      }, payload) {
        commit('setLoader', true)
        return client
          .deleteEmployee(payload)
          .then(response => {
            successToast('Employee deleted Successfully!')
            router.push('/employees/list')
            dispatch('fetchEmployees', {})
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
    
    
      fetchDoctorsAll({
        commit
      }) {
    
        return client
          .fetchDoctorsAll()
          .then(doctors => {
            commit('setDoctors', doctors.data)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchDoctors({
        commit
      }, payload) {
    
        return client
          .fetchDoctors(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
          .then(doctors => {
            commit('setDoctors', doctors.data)
            commit('setPagination', doctors.meta)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchDoctor({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .fetchDoctor(payload)
          .then(doctor => {
            commit('setDoctor', doctor)
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      createDoctor({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .createDoctor(payload)
          .then(response => {
            successToast('Doctor created Successfully!')
            router.push('/doctors/list')
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      updateDoctor({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .updateDoctor(payload.id, payload)
          .then(response => {
            successToast('Doctor updated Successfully!')
            router.push('/doctors/list')
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      deleteDoctor({
        commit,
        dispatch
      }, payload) {
        commit('setLoader', true)
        return client
          .deleteDoctor(payload)
          .then(response => {
            successToast('Doctor deleted Successfully!')
            router.push('/doctors/list')
            dispatch('fetchDoctors', {})
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
    
      fetchPatientsAll({
        commit
      }) {
    
        return client
          .fetchPatientsAll()
          .then(patients => {
            commit('setPatients', patients.data)
          })
          .catch(err => {
              console.log(err)
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },

      fetchSimplePatientsAll({
        commit
      }) {
    
        return client
          .fetchSimplePatientsAll()
          .then(patients => {
            commit('setPatients', patients.data)
          })
          .catch(err => {
              console.log(err)
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchPatients({
        commit
      }, payload) {
    
        return client
          .fetchPatients(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
          .then(patients => {
            commit('setPatients', patients.data)
            commit('setPagination', patients.meta)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchPatient({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .fetchPatient(payload)
          .then(patient => {
            commit('setPatient', patient)
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      createPatient({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .createPatient(payload)
          .then(response => {
            successToast('Record created Successfully!')
            router.push('/patients/list')
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      updatePatient({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .updatePatient(payload.id, payload)
          .then(response => {
            successToast('Patient updated Successfully!')
            router.push('/patients/list')
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      deletePatient({
        commit,
        dispatch
      }, payload) {
        commit('setLoader', true)
        return client
          .deletePatient(payload)
          .then(response => {
            successToast('Patient deleted Successfully!')
            router.push('/patients/list')
            dispatch('fetchPatients', {})
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },


      fetchLeadsAll({
        commit
      }) {
    
        return client
          .fetchLeadsAll()
          .then(leads => {
            commit('setLeads', leads.data)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchLeads({
        commit
      }, payload) {
    
        return client
          .fetchLeads(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
          .then(leads => {
            commit('setLeads', leads.data)
            commit('setPagination', leads.meta)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      fetchLead({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .fetchLead(payload)
          .then(lead => {
            commit('setLead', lead)
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      createLead({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .createLead(payload)
          .then(response => {
            successToast('Record created Successfully!')
            router.push('/leads/list')
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      updateLead({
        commit
      }, payload) {
        commit('setLoader', true)
        return client
          .updateLead(payload.id, payload)
          .then(response => {
            successToast('Lead updated Successfully!')
            router.push('/leads/list')
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },
    
      deleteLead({
        commit,
        dispatch
      }, payload) {
        commit('setLoader', true)
        return client
          .deleteLead(payload)
          .then(response => {
            successToast('Lead deleted Successfully!')
            router.push('/leads/list')
            dispatch('fetchLeads', {})
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },

      convertLead({
        commit,
        dispatch
      }, payload) {
        commit('setLoader', true)
        return client
          .convertLead(payload)
          .then(response => {
            successToast('Lead converted Successfully!')
            router.push('/leads/list')
            dispatch('fetchLeads', {})
            commit('setLoader', false)
          })
          .catch(err => {
              console.log(err.response)
              errorToast(`Oops! ${err.response.data.message}`)
              commit('setLoader', false);
          })
      },




    fetchGender({ commit }) {

        return client
            .fetchSettingByKey('gender')
            .then(response => {
                let data = response.value.split(',')
                commit('setGender', data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchMaritalStatus({ commit }) {

        return client
            .fetchSettingByKey('marital-status')
            .then(response => {
                let data = response.value.split(',')
                commit('setMaritalStatus', data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchReligion({ commit }) {

        return client
            .fetchSettingByKey('religion')
            .then(response => {
                let data = response.value.split(',')
                commit('setReligion', data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchBloodGroup({ commit }) {

        return client
            .fetchSettingByKey('blood-group')
            .then(response => {
                let data = response.value.split(',')
                commit('setBloodGroup', data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchConsultationFee({ commit }) {

        return client
            .fetchSettingByKey('consultation-fee')
            .then(response => {
                commit('setConsultationFee', response.value)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchAppointmentType({ commit }) {

        return client
            .fetchSettingByKey('appointment-type')
            .then(response => {
                let data = response.value.split(',')
                commit('setAppointmentType', data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchTransfersAll({ commit }) {

        return client
            .fetchTransfersAll()
            .then(transfers => {
                commit('setTransfers', transfers.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchTransfers({ commit }, payload) {

        return client
            .fetchTransfers(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(transfers => {
                commit('setTransfers', transfers.data)
                commit('setPagination', transfers.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchTransfer({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchTransfer(payload)
            .then(transfers => {
                commit('setTransfer', transfers)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createTransfer({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createTransfer(payload)
            .then(response => {
                successToast('Record created Successfully!')
                dispatch("fetchInventories", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateTransfer({ commit }, payload) {
        commit('setLoader', true)
        return client
            .updateTransfer(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchInventories", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteTransfer({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteTransfer(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchInventories", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchSettingsAll({ commit }) {

        return client
            .fetchSettingsAll()
            .then(settings => {
                commit('setSettings', settings.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchSettings({ commit }, payload) {

        return client
            .fetchSettings(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(settings => {
                commit('setSettings', settings.data)
                commit('setPagination', settings.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchSetting({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(setting => {
                commit('setSetting', setting)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createSetting({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createSetting(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchSettings", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateSetting({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateSetting(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchSettings", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteSetting({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteSetting(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchSettings", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    
    fetchImagesAll({ commit }) {

        return client
            .fetchImagesAll()
            .then(images => {
                commit('setImages', images.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchImages({ commit }, payload) {

        return client
            .fetchImages(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(images => {
                commit('setImages', images.data)
                commit('setPagination', images.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchImage({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchImage(payload)
            .then(image => {
                commit('setImage', image)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createImage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createImage(payload)
            .then(image => {
                successToast('Record created Successfully!')
                dispatch("fetchImages", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateImage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateImage(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchImages", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteSettingsImage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteSettingsImage(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchImages", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchDosagesAll({ commit }) {

        return client
            .fetchDosagesAll()
            .then(response => {
                commit('setDosages', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchDosages({ commit }, payload) {

        return client
            .fetchDosages(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setDosages', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchDosage({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setDosage', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createDosage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createDosage(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchDosages", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateDosage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateDosage(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchDosages", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteDosage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteDosage(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchDosages", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchFrequenciesAll({ commit }) {

        return client
            .fetchFrequenciesAll()
            .then(response => {
                commit('setFrequencies', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchFrequencies({ commit }, payload) {

        return client
            .fetchFrequencies(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setFrequencies', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchFrequency({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setFrequency', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createFrequency({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createFrequency(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchFrequencies", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateFrequency({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateFrequency(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchFrequencies", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteFrequency({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteFrequency(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchFrequencies", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchCommentsAll({ commit }) {

        return client
            .fetchCommentsAll()
            .then(response => {
                commit('setComments', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchComments({ commit }, payload) {

        return client
            .fetchComments(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setComments', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchComment({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setComment', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createComment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createComment(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchComments", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateComment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateComment(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchComments", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteComment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteComment(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchComments", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchProductTypesAll({ commit }) {

        return client
            .fetchProductTypesAll()
            .then(response => {
                commit('setProductTypes', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchProductTypes({ commit }, payload) {

        return client
            .fetchProductTypes(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setProductTypes', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchProductType({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setProductType', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createProductType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createProductType(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchProductTypes", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateProductType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateProductType(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchProductTypes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteProductType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteProductType(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchProductTypes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchDiscountsAll({ commit }) {

        return client
            .fetchDiscountsAll()
            .then(response => {
                commit('setDiscounts', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchDiscounts({ commit }, payload) {

        return client
            .fetchDiscounts(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setDiscounts', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchDiscount({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setDiscount', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createDiscount({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createDiscount(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchDiscounts", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateDiscount({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateDiscount(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchDiscounts", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteDiscount({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteDiscount(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchDiscounts", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchRolesAll({ commit }) {
        return client
            .fetchRolesAll()
            .then(response => {
                commit('setRoles', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchRoles({ commit }, payload) {

        return client
            .fetchRoles(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setRoles', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchRole({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setRole', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createRole({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createRole(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchRoles", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateRole({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateRole(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchRoles", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteRole({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteRole(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchRoles", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchRecordTypesAll({ commit }) {

        return client
            .fetchRecordTypesAll()
            .then(response => {
                commit('setRecordTypes', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchRecordTypes({ commit }, payload) {

        return client
            .fetchRecordTypes(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setRecordTypes', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchRecordType({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setRecordType', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createRecordType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createRecordType(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchRecordTypes", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateRecordType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateRecordType(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchRecordTypes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteRecordType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteRecordType(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchRecordTypes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },




    fetchRecordsAll({ commit }) {

        return client
            .fetchRecordsAll()
            .then(response => {
                commit('setRecords', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchRecords({ commit }, payload) {

        return client
            .fetchRecords(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setRecords', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchRecord({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setRecord', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createRecord({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createRecord(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchRecords", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateRecord({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateRecord(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchRecords", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteRecord({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteRecord(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchRecords", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchGSTTypesAll({ commit }) {

        return client
            .fetchGSTTypesAll()
            .then(response => {
                commit('setGSTTypes', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchGSTTypes({ commit }, payload) {

        return client
            .fetchGSTTypes(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setGSTTypes', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchGSTType({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setGSTType', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createGSTType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createGSTType(payload)
            .then(setting => {
                successToast('GSTType created Successfully!')
                dispatch("fetchGSTTypes", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateGSTType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateGSTType(payload.id, payload)
            .then(response => {
                successToast('GSTType updated Successfully!')
                dispatch("fetchGSTTypes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteGSTType({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteGSTType(payload)
            .then(response => {
                successToast('GSTType deleted Successfully!')
                dispatch("fetchGSTTypes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchModesAll({ commit }) {

        return client
            .fetchModesAll()
            .then(response => {
                commit('setModes', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchModes({ commit }, payload) {

        return client
            .fetchModes(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setModes', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchMode({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setMode', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createMode({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createMode(payload)
            .then(setting => {
                successToast('Mode created Successfully!')
                dispatch("fetchModes", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateMode({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateMode(payload.id, payload)
            .then(response => {
                successToast('Mode updated Successfully!')
                dispatch("fetchModes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteMode({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteMode(payload)
            .then(response => {
                successToast('Mode deleted Successfully!')
                dispatch("fetchModes", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    



    fetchAppointmentsAll({ commit }) {

        return client
            .fetchAppointmentsAll()
            .then(response => {
                commit('setAppointments', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchAppointments({ commit }, payload) {

        return client
            .fetchAppointments(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setAppointments', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchAppointmentsByDoctor({ commit }, payload) {

        return client
            .fetchAppointmentsByDoctor(payload.per_page || 10, payload.page || 1, payload.searchQuery || '', payload.doctor)
            .then(response => {
                commit('setAppointments', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchAppointment({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchAppointment(payload)
            .then(response => {
                commit('setAppointment', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createAppointment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createAppointment(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchAppointments", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateAppointment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateAppointment(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchAppointments", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteAppointment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteAppointment(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchAppointments", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },



    fetchEventsAll({ commit }) {

        return client
            .fetchEventsAll()
            .then(response => {
                commit('setEvents', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchEvents({ commit }, payload) {

        return client
            .fetchEvents(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setEvents', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchEvent({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchEvent(payload)
            .then(response => {
                commit('setEvent', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createEvent({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createEvent(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchEvents", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateEvent({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateEvent(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchEvents", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteEvent({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteEvent(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchEvents", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchInfosAll({ commit }) {

        return client
            .fetchInfosAll()
            .then(response => {
                commit('setInfos', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchInfos({ commit }, payload) {

        return client
            .fetchInfos(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setInfos', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchInfo({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchInfo(payload)
            .then(response => {
                commit('setInfo', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createInfo({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createInfo(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchInfos", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateInfo({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateInfo(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchInfos", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteInfo({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteInfo(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchInfos", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchDiseasesAll({ commit }) {

        return client
            .fetchDiseasesAll()
            .then(response => {
                commit('setDiseases', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchDiseases({ commit }, payload) {

        return client
            .fetchDiseases(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setDiseases', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchDisease({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchDisease(payload)
            .then(response => {
                commit('setDisease', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createDisease({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createDisease(payload)
            .then(setting => {
                successToast('Record created Successfully!')
                dispatch("fetchDiseases", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updateDisease({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updateDisease(payload.id, payload)
            .then(response => {
                successToast('Record updated Successfully!')
                dispatch("fetchDiseases", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deleteDisease({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteDisease(payload)
            .then(response => {
                successToast('Record deleted Successfully!')
                dispatch("fetchDiseases", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },




    deleteImage({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deleteImage(payload)
            .then(response => {
                successToast('Image deleted Successfully!')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },


    fetchCountries({ commit }) {

        return client
            .fetchCountries()
            .then(response => {
                // console.log('countries', response)
                commit('setCountries', response)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchPaymentsAll({ commit }) {

        return client
            .fetchPaymentsAll()
            .then(response => {
                commit('setPayments', response.data)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },
    
    fetchPayments({ commit }, payload) {

        return client
            .fetchPayments(payload.per_page || 10, payload.page || 1, payload.searchQuery || '')
            .then(response => {
                commit('setPayments', response.data)
                commit('setPagination', response.meta)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    fetchPayment({ commit }, payload) {
        commit('setLoader', true)
        return client
            .fetchSetting(payload)
            .then(response => {
                commit('setPayment', response)
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    createPayment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .createPayment(payload)
            .then(setting => {
                successToast('Payment created Successfully!')
                dispatch("fetchPayments", {})
                // router.push('/transfers/list')
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    updatePayment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .updatePayment(payload.id, payload)
            .then(response => {
                successToast('Payment updated Successfully!')
                dispatch("fetchPayments", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

    deletePayment({ commit, dispatch }, payload) {
        commit('setLoader', true)
        return client
            .deletePayment(payload)
            .then(response => {
                successToast('Payment deleted Successfully!')
                dispatch("fetchPayments", {})
                commit('setLoader', false)
            })
            .catch(err => {
                console.log(err.response)
                errorToast(`Oops! ${err.response.data.message}`)
                commit('setLoader', false);
            })
    },

}
