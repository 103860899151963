<template>
    <div class="record-box">
        <div class="row">
            <div class="col-4">
                <img class="thumbnail" :src="image_url"/>
            </div>
            <div class="col-5">
                <div class="details">
                    <h5>{{ record.title }}</h5>
                    <h6>{{ record.record_id }} <br> <small>{{ record.record_type.name }}</small> <br> <small>{{ formattedDate(record.date, false) }}</small></h6>
                </div>
            </div>
            <div class="col-3">
                <p-button type="defualt" size="sm" round icon @click.prevent="handleView(record)">
                    <i class="fa fa-eye"></i>
                </p-button>
                <p-button type="success" size="sm" round icon @click.prevent="handleDownload(record)">
                    <i class="fa fa-download"></i>
                </p-button>
                <!-- <p-button type="danger" size="sm" icon @click.prevent="handleDelete(record)">
                    <i class="fa fa-trash"></i>
                </p-button> -->
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
export default {
    name: 'RecordBox',
    props: {
        record: Object
    },
    computed: {
        image_url(){
            return (this.record.image_url.split('.').pop() != 'pdf') ? this.record.image_url : `/static/img/pdf.png`;
        }
    },
    methods: {
        handleDelete(record) {
            swal({
                title: "Are you sure?",
                text: `You won't be able to revert this!`,
                showCancelButton: true,
                confirmButtonClass: "btn btn-danger btn-fill",
                cancelButtonClass: "btn btn-default btn-fill",
                confirmButtonText: "Delete it!",
                buttonsStyling: false
            })
            .then(result => { this.$store.dispatch("deleteRecord", record.id); })
            .catch(cancel => { console.log(cancel) });

            // this.$store.dispatch("deleteRecord", row.id);
        },
        handleDownload(record) {
            axios({
            url: record.image_url,
            method: 'get',
            responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                let ext = record.image_url.split('.').pop();
                link.setAttribute("download", `${record.record_id}-${record.title}.${ext}`);
                document.body.appendChild(link);
                link.click();
            });
        // window.open(`${row.imageURL}`, "_blank", );
        },
        handleView(record) {
            window.open(`${record.image_url}`, "_blank", );
        },
        formattedDate(date, isTime = true){
            var d = new Date(date);
            let options = (isTime) ? { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' } : { year: 'numeric', month: 'long', day: 'numeric' } ;
            return  d.toLocaleDateString('en-US', options); 
        }
    }
}
</script>

<style>
.record-box{
    box-shadow: 0px -4px 5px rgba(0,0,0,0.15);
    border-radius: 10px;
    min-height: 100px;
}
img.thumbnail{
    height: auto;
    width: 100%;
    padding: 15%;
}
</style>
